@import url('../plugins/font-awesome-4.7.0/css/font-awesome.min.css');
@import url('../plugins/animate.min.css');
@import url('../plugins/bootstrap.min.css');
@import url('../plugins/bootstrap-select.min.css');
@import url('../plugins/jquery-ui/jquery-ui.css');
@import url('../plugins/timePicker.css');
@import 'variables';
@import 'typography';
@import 'media-queries';
@import 'common';
@import 'templates/header';
@import 'templates/navigation';
@import 'templates/accordions';
@import 'templates/slider';
@import 'templates/cta';
@import 'templates/contact';
@import 'templates/welcome';
@import 'templates/two-column';
@import 'templates/services';
@import 'templates/team';
@import 'templates/testimonials';
@import 'templates/blog';
@import 'templates/sponsors';
@import 'templates/about';
@import 'templates/gallery';
@import 'templates/skills';
@import 'templates/page-title';
@import 'templates/pagination';
@import 'templates/footer';
@import 'responsive';