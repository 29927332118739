.testimonial-section {
  position: relative;
  background-size: cover !important;
  padding: 110px 0px;
  background-repeat: no-repeat !important;
  &:before {
    position: absolute;
    top: 0px;
    left: 0px;
    content: '';
    width: 100%;
    height: 100%;
    background: rgba($color: $primary-color, $alpha: 0.7);
  }
  .section-title h3 {
    position: relative;
    padding-bottom: 30px;
    color: #ffffff;
    span {
      color: #ffffff;
    }
  }
  .testimonial-carousel{
    .slide-item{
      outline: 0;
    }
    .slick-dots li button:before{
      font-size: 15px;
      color: $light;
    }
  }
  .inner-box{
    position: relative;
    padding: 60px 30px;
    background: $light;
    margin-top: 50px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.2);
  }
  .inner-box {
    h6 {
      position: relative;
      font-size: 18px;
      line-height: 26px;
      font-weight: 600;
      color: $black;
      padding-bottom: 15px;
      text-transform: uppercase;
      font-weight: normal;
    }
    p {
      font-size: 15px;
      line-height: 24px;
      font-weight: 400;
      color: lighten($color: $black, $amount: 10%);
    }
    .image-box {
      position: absolute;
      top: -45px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 50%;
      border: 5px solid $light;
      img{
        width: 90px;
        height: 90px;
        border-radius: 50%;
      }
    }
  }
}